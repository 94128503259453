/*------Drop Downs-------*/

.dropup, .dropright, .dropdown, .dropleft {
	position: relative;
  }
  
  .dropdown-toggle {
	&::after {
	  display: inline-block;
	  width: 0;
	  height: 0;
	  margin-right: 0.255em;
	  vertical-align: 0.255em;
	  content: "";
	  border-top: 0.3em solid;
	  border-right: 0.3em solid transparent;
	  border-bottom: 0;
	  border-left: 0.3em solid transparent;
	  color: #c4c9d6;
	}
  
	&:empty::after {
	  margin-left: 0;
	}
  }
  
  .dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 0.9375rem;
	color: #495057;
	text-align: right;
	list-style: none;
	background-color: $white;
	background-clip: padding-box;
	box-shadow: none;
	min-width: 12rem;
	box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.1);
	border-radius: 4px;
	border: 1px solid $border;
  }
  
  .dropdown-menu-right {
	right: 0;
	left: auto;
  }
  
  .dropup {
	.dropdown-menu {
	  top: auto;
	  bottom: 100%;
	  margin-top: 0;
	  margin-bottom: 0.125rem;
	}
  
	.dropdown-toggle {
	  &::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0;
		border-right: 0.3em solid transparent;
		border-bottom: 0.3em solid;
		border-left: 0.3em solid transparent;
	  }
  
	  &:empty::after {
		margin-left: 0;
	  }
	}
  }
  
  .dropright {
	.dropdown-menu {
	  top: 0;
	  right: auto;
	  left: 100%;
	  margin-top: 0;
	  margin-left: 0.125rem;
	}
  
	.dropdown-toggle {
	  &::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid transparent;
		border-right: 0;
		border-bottom: 0.3em solid transparent;
		border-left: 0.3em solid;
		vertical-align: 0;
	  }
  
	  &:empty::after {
		margin-left: 0;
	  }
	}
  }
  
  .dropleft {
	.dropdown-menu {
	  top: 0;
	  right: 100%;
	  left: auto;
	  margin-top: 0;
	  margin-right: 0.125rem;
	}
  
	.dropdown-toggle {
	  &::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		display: none;
	  }
  
	  &::before {
		display: inline-block;
		width: 0;
		height: 0;
		margin-right: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid transparent;
		border-right: 0.3em solid;
		border-bottom: 0.3em solid transparent;
		vertical-align: 0;
	  }
  
	  &:empty::after {
		margin-left: 0;
	  }
	}
  }
  
  .header .dropdown-menu {
	&[x-placement^="top"], &[x-placement^="right"], &[x-placement^="bottom"], &[x-placement^="left"] {
	  top: 62px !important;
	  border-radius: 6px;
	  animation-name: fadeInUp;
	  border: 1px solid $border;
	  box-shadow: 0px 16px 18px rgba(4, 4, 7, 0.1);
	}
  }
  
  .header-notify.show .dropdown-menu {
	right: -180px !important;
  }
  
  .header-message.show .dropdown-menu {
	right: -250px !important;
  }
  
  .profile-dropdown.show .dropdown-menu[x-placement^="bottom"] {
	right: -140px !important;
  }
  
  .dropdown-menu {
	&[x-placement^="top"], &[x-placement^="right"], &[x-placement^="bottom"], &[x-placement^="left"] {
	  right: auto;
	  bottom: auto;
	}
  }
  
  .dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e9ecef;
  }
  
  .notifyimg {
	color: $white;
	float: left;
	height: 40px;
	line-height: 43px;
	margin-left: 20px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
	border-radius: 50%;
  }
  
  .dropdown-item {
	display: block;
	width: 100%;
	padding: 0.5rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
  }
  
  .drop-icon-wrap {
	text-align: center;
  
	li {
	  display: block;
	}
  
	.drop-icon-item {
	  display: inline-block;
	  padding-bottom: 10px;
	  padding-top: 10px;
	  text-align: center;
	  color: #878787;
	  text-transform: capitalize;
	  width: 50%;
	  float: left;
	}
  }
  
  .block {
	display: block !important;
  }
  
  .drop-icon-item i {
	font-size: 20px;
	padding-bottom: 5px;
  }
  
  .drop-icon-wrap .drop-icon-item {
	&:hover, &:focus {
	  text-decoration: none;
	  background-color: #eef0ff;
	}
  }
  
  .dropdown-item.user {
	font-size: 16px;
	padding: 5px;
  }
  
  .user-semi-title {
	font-size: 12px;
  }
  
  .floating {
	animation: floating 3s ease infinite;
	will-change: transform;
  }
  
  .dropdown-item {
	&:hover, &:focus {
	  text-decoration: none;
	  background-color: #f0f0f2;
	}
  
	&.active, &:active {
	  text-decoration: none;
	  background-color: #f0f0f2;
	  color: #3e4358;
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: transparent;
	}
  }
  
  .dropdown-menu.show {
	display: block;
	margin: 0;
  }
  
  .dropdown-menu-arrow {
	&.dropdown-menu-right {
	  &:before {
		content: '';
		position: absolute;
		top: -10px;
		left: 15px;
		border-bottom: 10px solid $border;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	  }
  
	  &:after {
		content: '';
		position: absolute;
		top: -8.5px;
		left: 16px;
		border-bottom: 10px solid $white;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	  }
	}
  
	&.dropdown-menu-left {
	  &:before {
		content: '';
		position: absolute;
		top: -10px;
		left: 15px;
		border-bottom: 10px solid $border;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	  }
  
	  &:after {
		content: '';
		position: absolute;
		top: -8.5px;
		left: 16px;
		border-bottom: 9px solid $white;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
	  }
	}
  }
  
  .nav-item.show .dropdown-menu.dropdown-menu-arrow.show {
	top: -1px !important;
  }
  
  .dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: $gray;
	white-space: nowrap;
  }
  
  .dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
  }
  
  .btn-group, .btn-group-vertical {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
  }
  
  .btn-group > .btn, .btn-group-vertical > .btn {
	position: relative;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
  }
  
  .btn-group > .btn:hover, .btn-group-vertical > .btn:hover {
	z-index: 1;
  }
  
  .btn-group > .btn {
	&:focus, &:active, &.active {
	  z-index: 1;
	}
  }
  
  .btn-group-vertical > .btn {
	&:focus, &:active, &.active {
	  z-index: 1;
	}
  }
  
  .btn-group {
	.btn + {
	  .btn, .btn-group {
		margin-left: -1px;
	  }
	}
  
	.btn-group + {
	  .btn, .btn-group {
		margin-left: -1px;
	  }
	}
  }
  
  .btn-group-vertical {
	.btn + {
	  .btn, .btn-group {
		margin-left: -1px;
	  }
	}
  
	.btn-group + {
	  .btn, .btn-group {
		margin-left: -1px;
	  }
	}
  }
  
  .btn-toolbar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
  
	.input-group {
	  width: auto;
	}
  }
  
  .btn-group > {
	.btn {
	  &:first-child {
		margin-left: 0;
	  }
  
	  &:not(:last-child):not(.dropdown-toggle) {
		border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
	  }
	}
  
	.btn-group:not(:last-child) > .btn {
	  border-top-left-radius: 0;
	  border-bottom-left-radius: 0;
	}
  
	.btn:not(:first-child), .btn-group:not(:first-child) > .btn {
	 border-top-right-radius: 0px;
     border-bottom-right-radius: 0px;
     border-top-left-radius: 3px;
     border-bottom-left-radius: 3px;
	}
  }
  
  .dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
  
	&::after {
	  margin-left: 0;
	}
  }
  
  .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
	margin-left: 0;
  }
  
  .dropleft .dropdown-toggle-split::before {
	margin-right: 0;
  }
  
  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
  }
  
  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
  }
  
  .btn-group-vertical {
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
  
	.btn, .btn-group {
	  width: 100%;
	}
  
	> {
	  .btn + {
		.btn, .btn-group {
		  margin-top: -1px;
		  margin-left: 0;
		}
	  }
  
	  .btn-group + {
		.btn, .btn-group {
		  margin-top: -1px;
		  margin-left: 0;
		}
	  }
  
	  .btn:not(:last-child):not(.dropdown-toggle), .btn-group:not(:last-child) > .btn {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	  }
  
	  .btn:not(:first-child), .btn-group:not(:first-child) > .btn {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	  }
	}
  }
  
  .btn-group-toggle > {
	.btn, .btn-group > .btn {
	  margin-bottom: 0;
	}
  
	.btn input {
	  &[type="radio"], &[type="checkbox"] {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		pointer-events: none;
	  }
	}
  
	.btn-group > .btn input {
	  &[type="radio"], &[type="checkbox"] {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		pointer-events: none;
	  }
	}
  }
  
  /*-----List Of Dropdwons-----*/
  
  btn.dropdown-toggle ~ .dropdown-menu {
	background-color: rgb(244, 244, 244) !important;
	background-color: rgb(255, 255, 255) !important;
	border: 0 solid rgb(66, 133, 244) !important;
	box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
	top: 0px !important;
	margin: 0px !important;
	padding: 0px !important;
  }
  
  ul.dropdown-menu li.dropdown ul.dropdown-menu {
	background-color: rgb(244, 244, 244) !important;
	background-color: rgb(255, 255, 255) !important;
	border: 0 solid rgb(66, 133, 244) !important;
	box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
	top: 0px !important;
	margin: 0px !important;
	padding: 0px !important;
	position: absolute !important;
	width: 100% !important;
  }
  
  .dropdown-menu {
	> li > a {
	  display: block;
	  padding: 0.5rem 1.5rem;
	  clear: both;
	  font-weight: 400;
	  line-height: 1.428571429;
	  color: #333;
	  white-space: nowrap;
  
	  &:hover, &:focus {
		text-decoration: none;
		color: $color;
		background-color: #f0f0f2;
	  }
	}
  
	.divider {
	  height: 1px;
	  margin: 9px 0;
	  overflow: hidden;
	  background: $border;
	}
  
	.dropdown-plus-title {
	  width: 100% !important;
	  color: #6b6f80 !important;
	  padding: 6px 12px !important;
	  font-weight: 500 !important;
	  border: 0 solid $border !important;
	  border-bottom-width: 1px !important;
	  cursor: pointer !important;
	}
  }
  
  ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	line-height: 20px !important;
  }
  
  .btn.dropdown-toggle {
	&.btn-success ~ .dropdown-menu .dropdown-plus-title {
	  border-color: $success !important;
	}
  
	&.btn-info ~ .dropdown-menu .dropdown-plus-title {
	  border-color: $info !important;
	}
  
	&.btn-warning ~ .dropdown-menu .dropdown-plus-title {
	  border-color: $warning !important;
	}
  
	&.btn-danger ~ .dropdown-menu .dropdown-plus-title {
	  border-color: $danger !important;
	}
  }
  
  .dropdown-menu-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 15px;
	border-bottom: 1px solid $border;
  }
  
  .dropdown-demo .dropdown-menu {
	position: static;
	float: none;
  }
  
  .dropdown-menu-header label {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #6b6f80;
  }
  
  .dropdown-media-list .media {
	&:hover, &:focus {
	  background-color: #f8f9fa;
	}
  
	padding: 12px 15px;
  }
  
  .media {
	display: flex;
	align-items: flex-start;
  }
  
  .dropdown-media-list {
	img {
	  border-radius: 100%;
	}
  
	.media-body {
	  margin-left: 15px;
	}
  }
  
  .media-body {
	flex: 1;
  }
  
  .dropdown-media-list .media-body > {
	div {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
  
	  p {
		margin-bottom: 0;
		font-weight: 500;
		color: #6b6f80;
		font-size: 14px;
	  }
  
	  span {
		font-size: 12px;
	  }
	}
  
	p {
	  font-size: 14px;
	  margin-bottom: 0;
	}
  }
  
  .dropdown-list-footer {
	padding: 10px 15px;
	text-align: center;
	font-size: 12px;
	border-top: 1px solid $border;
  }
  
  .label {
	display: inline-block;
	margin-bottom: .5rem;
  }
  
  .dropdown-media-list {
	padding: 0;
  }
  
  @media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu {
	  .dropdown-plus-title {
		padding-top: 15px !important;
		padding-bottom: 15px !important;
	  }
  
	  width: auto !important;
	}
  }
  
  .dropdown {
	display: block;
  }
  
  #sidebar .dropdown-menu {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	min-width: 12rem;
  }
  
  .dropdown-menu-arrow.dropdown-menu-right {
	&:before, &:after {
	  right: auto;
	  left: 12px;
	}
  }
  
  .dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
  
	&:after {
	  vertical-align: 0.155em;
	}
  
	&:empty:after {
	  margin-left: 0;
	}
  }
  
  .dropdown-icon {
	color: #3e4358;
	margin-right: -.5rem;
	margin-left: .5rem;
	width: 1em;
	display: inline-block;
	text-align: center;
	vertical-align: -1px;
  }
  
  .header {
	.profile-dropdown .dropdown-menu {
	  top: 58px !important;
	}
  
	.header-dropdown-list {
	  height: 300px;
	}
  }
  
  .dropdown-header {
	padding: 15px;
	border-bottom: 1px solid $border;
	color: $color;
	display: flex;
  }
  
  .dropdown-toggle.split-dropdown {
	border-left: 1px solid rgba(0, 0, 0, 0.2);
  }